// https://stackoverflow.com/questions/52952475/change-styles-scss-dynamically-in-angular
@use '@angular/material' as mat;
//https://github.com/angular/material.angular.io/blob/master/angular.json




//
// style processor option angular.json
//https://angular.io/guide/workspace-config#style-script-config

//  https://material.angular.io/guide/theming-your-components
// apply theming for footer
// apply also theming for other elements!

// todo: checkout, if this works
// if not use this in configuarion angular json
// "./node_modules/@angular/material/prebuilt-themes/indigo-pink.css",
  
// so far not really required here.
//@import '@angular/material/prebuilt-themes/indigo-pink.css';
//import red from '@material-ui/core/colors/red';
// mat-palette accepts $palette-name, main, lighter and darker variants

// currenty just test purpose

// $my-theme-primary: mat-palette($mat-red, 600, 300, 900);
// $my-theme-accent: mat-palette($mat-blue-grey, 700, 300, 900);
// $my-theme-warn: mat-palette($mat-pink, 700, 300, 900);
// // create theme (use mat-dark-theme for themes with dark backgrounds)
// $my-theme: mat-light-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);


// define a new app-theme
// not clear yet how it can be applied after deploy!

// $candy-app-primary: mat-palette($mat-indigo);
// $candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $candy-app-warn:    mat-palette($mat-red);

// // Create the theme object (a Sass map containing all of the palettes).
// $candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($candy-app-theme);






@import '@angular/material/theming';

// always include only once per project
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// define a differnt light theme.
// $primary: mat-palette($mat-red);
// $accent: mat-palette($mat-pink, A200, A100, A400);
// $warn: mat-palette($mat-red);
// $theme: mat-light-theme($primary, $accent, $warn); // Or mat-dark-theme

// @include angular-material-theme($theme);



$primary: mat.define-palette(mat.$indigo-palette);
$primary-color: mat.define-palette(mat.$grey-palette);

@import '@angular/material/prebuilt-themes/indigo-pink.css';

// das funktioniert nur wenn beide dateien im hauptverzeichnis sind
@import "vars";

// die funktionieren nicht so richtig
//@import "/src/styles/vars.css";
//@import "/src/styles/variables";
// =>

// vars direkt in die Datei einbinden
// begin vars













/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #E0E0E0;  
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ADADAD; 
}
//$primary-color:$primary;

// $dark-theme: mat-dark-theme($primary-color, $accent-color, $warning-color);
// $light-theme: mat-light-theme($primary-color, $accent-color, $warning-color);
.navigation {
  // Get the default hue for a palette.
  //color: mat-color($primary);
  color: red;
  margin-right: -10px;
  padding-right: -10px;
  //background-color: $primary-color;
  // not sure if widht is better, however for languge changes the width also changes
  //width:8em;
  // Get a specific hue for a palette. 
  // See https://material.io/archive/guidelines/style/color.html#color-color-palette for hues.
  //background-color: mat-color($accent, 300);

  // Get a relative color for a hue ('lighter' or 'darker')
  //outline-color: mat-color($accent, lighter);

  // Get a contrast color for a hue by adding `-contrast` to any other key.
  //border-color: mat-color($primary, '100-contrast');
}

// .desktop-footer-bar {
//   // Get the default hue for a palette.
  

//   // Get a specific hue for a palette. 
//   // See https://material.io/archive/guidelines/style/color.html#color-color-palette for hues.
//   //background-color: mat-color($accent, 300);

//   // Get a relative color for a hue ('lighter' or 'darker')
//   //outline-color: mat-color($accent, lighter);

//   // Get a contrast color for a hue by adding `-contrast` to any other key.
//   //border-color: mat-color($primary, '100-contrast');
// }




//import red from '@material-ui/core/colors/red';
// import my-theme
//@import 'theme.scss';





// https://medium.com/javascript-training/beginner-s-guide-to-webpack-b1f1a3638460
// https://egghead.io/lessons/webpack-import-css-modules-with-typescript-and-webpack
// https://www.bluematador.com/blog/how-to-share-variables-between-js-and-sass



// indigo-pink.css
// purple-green.css
// pink-bluegrey.css
// deeppurple-amber.css






html,body {
font-family: #{$font-family};
display: flex;
height: 100vh;
flex-direction: column;

// box-sizing: border-box;
padding: 0em;
margin: 0em;


}

/*
also applied mat-drawer mat-sidenav
*/

/* currently full height of the content*/
.main-container {

  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 0em;
  padding:0em;



}


.app-root {

  width: #{$application-width};
  margin: 0em;
  padding:0em;
  flex-direction: column;
  display: flex;
  
  //desktop
  height: 100%;
  
  // without that second scrolling bar.
//   @media only screen and (min-width: #{$increasing-screen-size}) {
//     height: calc(100% - 0.05em); 
//   }
}


/* implement delay for mobile footer */



.nav-footer-bar {
  height :#{$footer-height};  
  color:$primary-color-font;
  /* fixed buttom */
  bottom: 0em;
  @media only screen and (min-width: calc(#{$change-desktop-footer-width} + 1px)) {
    margin: 0 auto;
    margin-top: 1em;
    // background-color: $list-background-color;
    //background-color: $primary-color;
  }
  @media only screen and (max-width: #{$change-desktop-footer-width}) {
    
    position: fixed;
    z-index: 2;
    background-color: $primary-color;

  }
  
  left: 0px;
  width: #{$application-width};
}




.fixedLeft {
  float: left;
  
  @media only screen and (max-width: #{$change-desktop-footer-width}) {
    padding-left: 0.5em;

  }
  @media only screen and (min-width: calc(#{$change-desktop-footer-width} + 1px)) {
    padding-left: 0em;
  }

}
.fixedRight {
  float: right;


  @media only screen and (max-width: #{$change-desktop-footer-width}) {
    padding-right: 0.5em;
  }
  @media only screen and (min-width: calc(#{$change-desktop-footer-width} + 1px)) {
    padding-right: 0em;
  }
}


.desktop-footer-bar{
  @media only screen and (max-width: #{$change-desktop-footer-width}) {
    display: none  
  } 

  .mat-toolbar, .mat-toolbar-row {
    height: #{$destop-footer-height} !important;
  
    line-height: 10px;
  } 

}


.desktop-footer-text-bar {
  vertical-align: middle;
  //float: right;
  color:$primary-color-font;
  //margin:1em;
}

a.footer-link{
  text-decoration: none;
}


 .mat-stepper {
  display:flex;
  //text-align: center; __<<ngThemingMigrationEscapedComment8>>__
  flex: 1;
  //height: 100%;
 }




app-stepper.ng-star-inserted{

  //background-color:yellow;
  
  // important that the footer moves to the button for mobile
  // for mobile footer. aliging to the bottom from the beginning.
  @media only screen and (max-width: #{$change-desktop-footer-width}) {
    height: calc(100% - #{$footer-height} - 56px);
    //height: 95%;
  }
}
 

.stepper-container {
  @media only screen and (min-width: $stepper-change-midformat-size) {
    margin: 0 auto;
    width:60%;
  }
  //height: 100%

}

.mat-horizontal-content-container {

  //height: 95%
}


.mat-sidenav-container {
  background-color:#{$background-color};
  flex: 1;
  flex-direction: column;
  display:flex;
  //height: 100%;

}
/* make a fixed toolbar*/
.nav-toolbar {
  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  top: 0; /* Sets the sticky toolbar to be on top */
  z-index: 1000; /* Ensure that your app's content doesn't overlap the toolbar */
}
/* should be a fixed width because of language changes */
/* other possibility would be a language defined with */
.mat-nav-list{

  width: 220px;
}

.header-picture {
  display: flex;
  height: #{$header-picture-height};

}

// disappearing line between primary header toolboxes
.mat-drawer-side {
  border: none;
}


/* left nav */
.navigation { 
  box-shadow:  2px 0 6px rgba(0,0,0,0.24);
  display:flex;
  
  // maybe fixed width on the left?
  // changing language nav left change to different width.
  //width:15em;
  height: 100%;
}

/* includes the toolbar*/
.sidenav-content {
  display:flex;

  // flex: 2 2 12em;
  flex-direction: column;
  //flex-grow:1;

   //height: 100%;

   /* no idea why not filled with inner content*/
   //background-color: green;
   border-color:$primary-color;
  

   /* interesting option to fill the space */
   ;
  //  border-style: solid;
  //  border-bottom-width: 0.05em;
  //  border-left-width: 0.05em;
  //  border-right-width: 0.05em;

  // margin: 5px;
  // padding:5px;


}


/* background- color is set in the html */
.nav-toolbar {
  // background-color: red;
   // position: fixed;
   // height: 30px;
   // top: 0;
   // left: 0;
   // right: 0;
 }


 // only for the application title component
 .page-title{
  display:none;
 }

 .mat-stepper {
  display:flex;
  //text-align: center; __<<ngThemingMigrationEscapedComment20>>__
  flex: 1;
  //height: 100%;

 }

 .page-content {
    margin: 1em;
 }
 .content-header-height {
  //margin-top: 0em;
  margin-bottom: 1em;
}


.infotext {

  margin: 4px;
  padding: 4px;
}


.line-break {
  width: 100%;
}

.mat-mdc-form-field-error{
  display: inline-block !important;
}


/* define the location items: important! depends on content */

 /* this is an important high, depends on content
mustn't be to small*/


.mat-expansion-panel-header-title{

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
//location form {

  .vertical-radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    flex-wrap: wrap;
  }

  .concern_list-content {
    background-color:$list-background-color;
    display: flex;  
    //height: 3em;
    align-items: center;
    border-bottom: solid 1px  $line-color;
    border-radius: 5px;

    @media only screen and (max-width: 600px) {
      flex-flow: column; 
    }

    @media only screen and (min-width: 601px) {
      //height: 3em;   
    }


    //border: solid 4px  $primary-color;
  
  }

  .location-radio-button  {
    //display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $list-background-color;
    padding-left: 0.5em;
    
    border-bottom-style: solid;
    //border-bottom-color: white;
    
    border-bottom-width: 0.05em;
    border-radius: 5px;
    @media only screen and (max-width: 600px) {
      height: #{$location-row-high-small};
      border-bottom-width: 0.1em;
    }
    @media only screen and (min-width: 601px) {
      border-bottom-width: 0.1em;
      height: #{$location-row-high};
    }
  
  }
  // a:link, a:visited, a:hover, a:active 

a {
  color:$primary-color;
}


.location-list{

  display: flex;
  //justify-content: space-between;
  font-size: 0.8em;
  width: 99%;
  //flex-wrap: wrap;
  background-color: $list-background-color;
  padding-left: 0.5em;

  height: #{$location-row-high};
  border-bottom-style: solid;
  border-bottom-color: white;
  border-bottom-width: 0.05em;
  border-radius: 5px;
  @media only screen and (max-width: 600px) {
    height: #{$location-row-high-small};
    //border-bottom-width: 0.5em;
    flex-flow: column;
    margin-bottom: 0.5em;
  }
  @media only screen and (min-width: 601px) {
    height: #{$location-row-high};
  }
}

.location-item-01 {
  
  flex-wrap: wrap;
  flex-basis: #{$location-item1-width};
  flex-grow:0;
  flex-shrink:0;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;
  vertical-align: middle;
  align-self: flex-start;
  @media only screen and (max-width: 599px) {
    //height: #{$location-item1-high-small};
    flex-shrink:1;
  }

  @media only screen and (min-width: 801px) {
    flex-basis: #{$location-item1-width-big};
  }

}

.location-item-02 {
  flex-basis: #{$location-item2-width};
  flex-grow:2;
  flex-shrink:1;
  flex-wrap: wrap;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;

  @media only screen and (max-width: 599px) {
    //height: #{$location-item2-high-small};
    flex-grow:1;
    flex-shrink:1;
  }

  @media only screen and (min-width: 801px) {
    width: #{$location-item2-width-big};
  }

}

.location-item-03 {
  flex-basis:#{$location-item3-width};
  flex-grow:1;
  flex-shrink:1;
  
  flex-wrap: wrap;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;


  @media only screen and (max-width: 599px) {
    //height: #{$location-item3-high-small};
  }
  @media only screen and (min-width: 801px) {
    width: #{$location-item3-width-big};
    //height: #{$location-item-high};
  }
}

.location-item-space {
  background-color: $line-color;
  flex-basis: 0.1em;
  flex-grow:0;
  flex-shrink:0;
  @media only screen and (max-width: 599px) {
    width: 100%;
    height: 1px;
  }

}
.location-item-space-end {

  width: 0.1em;
  flex-grow:2;
  flex-shrink:4;
}


  /* display the text for checkboxes and radion buttons on the next line */

  /* contact form */
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    white-space: normal !important;
  } 

/* location form */
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label {
    @media only screen and (min-width: 601px) {
      white-space: normal !important;
    }
    
  } 
  
  .overlay-page {

    display:flex;
    flex-direction: column;
    // width: 100%;
    height: 97%;
  
  }
  
  .mat-mdc-dialog-actions{
    //background-color: green;
    padding-bottom: 0px;
    // align-self:flex-end
    margin-top: auto;
    align-self: flex-end;
  }
  
  .dayEntry {
    background: $list-background-color;
    border-radius: 100%;
    font-weight: bold;

  }
  
  // https://stackoverflow.com/questions/924916/is-there-a-way-to-make-a-div-unselectable
  // https://www.w3schools.com/cssref/css_selectors.asp
  .no-select{
    -webkit-user-select: none;
    //cursor:default; __<<ngThemingMigrationEscapedComment30>>__
  }
 
  .dayNoEntry > div {

    color:$dayNoEntryFontColor;

    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    //cursor:default; __<<ngThemingMigrationEscapedComment31>>__

  }
  .dayNoEntry{

    background: #{$dayNoEntryBackGroundColor};
    border-radius: 100%;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-select: none;
   // cursor:default; __<<ngThemingMigrationEscapedComment32>>__
  }

  /* hide other calendar options for selectind date, only move forward and backwards possible*/
  .mat-calendar-period-button {
    display: none !important;;  
  }

/* big devices {*/
  @if ($application-width == '100%') { 

    /* resizing elements > 1024 */
    html,body {
      @media only screen and (min-width: #{$increasing-screen-size}) {
        // tobi: von 1.2em geändert
        font-size: 1.0em;
      }
    }
    .mat-content {
      @media only screen and (min-width: #{$increasing-screen-size}) {
        // tobi: von 1.2em geändert
        font-size: 1.0em;
        //flex-basis: 26em;
      }
    
    }
    .mat-step-label
    {
      @media only screen and (min-width: #{$increasing-screen-size}) {
        font-size: 0.9em;
        //flex-basis: 26em;
      }
    }
    
    .list-item {
      @media only screen and (min-width: #{$increasing-screen-size}) {
        //font-size: 1.2em; // TOBI
      } 
    }
    
    .nav-footer-bar {
      @media only screen and (min-width: #{$increasing-screen-size}) {
        font-size: 0.8em;
      } 
    }
    
    .fixedLeft {
      @media only screen and (min-width: #{$increasing-screen-size}) {
        //font-size: 1.1em;
        //height: 60px;
      } 
    }
    .fixedRight {
      @media only screen and (min-width: #{$increasing-screen-size}) {
        //font-size: 1.1em;
        //height: 60px;
      } 
    
    }
    
    .mat-ripple {
      @media only screen and (min-width: #{$increasing-screen-size}) {
        font-size: 1.1em;
        //height: 60px;
      } 
    }
    }
    /* big devices  } */ 


// not sure if required.


// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

//@include mat-card-theme($theme);

  // mat-typography todo check it out


  
/* resizing elements < 1024 */

// html,body {
//   @media only screen and (max-width: 1024px) {
//     font-size: 0.8em;
//   }
// }
// .mat-content {
//   @media only screen and (max-width: 1024px) {
//     font-size: 0.9em;
//     //flex-basis: 26em;
//   }

// }
// .mat-step-label
// {
//   @media only screen and (max-width: 1024px) {
//     font-size: 0.7em;
//     //flex-basis: 26em;
//   }
// }

// .list-item {
//   @media only screen and (max-width: 1024px) {
//     font-size: 0.8em;
//   } 
// }

// .nav-footer-bar {
//   @media only screen and (max-width: 1024px) {
//     font-size: 0.9em;
//     height: 60px;
//   } 
// }

// .fixedLeft {
//   @media only screen and (max-width: 1024px) {
//     font-size: 0.9em;
//     //height: 60px;
//   } 
// }
// .fixedRight {
//   @media only screen and (max-width: 1024px) {
//     font-size: 0.9em;
//     //height: 60px;
//   } 

// }

// .mat-ripple {
//   @media only screen and (max-width: 1024px) {
//     font-size: 0.9em;
//     //height: 60px;
//   } 
// }


//   .button {
//     background-color: red;
//     color:green;

// }
//   .mat-calendar  {

//     .mat-calendar-header {
//         .button{
//                background-color: red;
//         }
//     }
// }


// .mat-calendar-next-button {

//   background-color: red;
// }
//mat-icon-button
  // .mat-calendar-body-cell-content {
  //   color:green;
  //   background-color: pink;

  // }


  // @media all and (max-width: 800px){
  //   .flex-container{
  //     justify-content: flex-start; 
  //   }
  // }

 



// @mixin calendar-theme($theme) {
//   // Extract the palettes you need from the theme definition.
//   $primary: map-get($theme, primary);
//   $accent: map-get($theme, accent);

//   // Define any styles affected by the theme.
//   // .dayEntry {
//   //   // Use mat-color to extract individual colors from a palette.
//   //   background-color: mat-color($primary);
//   //   border-color: mat-color($accent, A400);
//   // }
// }


/* You can add global styles to this file, and also import other style files */


/* */
// @mixin calendar-theme($theme) {
//   // Extract the palettes you need from the theme definition.
//   $primary: map-get($theme, primary);
//   $accent: map-get($theme, accent);

//   // Define any styles affected by the theme.
//   .dayEntry {
//     // Use mat-color to extract individual colors from a palette.
//     background-color: mat-color($primary);
//     border-color: mat-color($accent, A400);
//     border-radius: 100%;
//     font-weight: bold;
//   }
// }


/* disabling stepper steps*/
// .mat-step-header {
//   pointer-events: none !important;
// }

// .mat-horizontal-stepper-header{
//   pointer-events: none !important;
// }