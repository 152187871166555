

$primary-color:green;
// $primary-color:#3F51B5;

// define a different light theme:

// rot
// $primary: mat-palette($mat-red, 600, 300, 900);
// $accent: mat-palette($mat-blue-grey, 700, 300, 900);
// $warn: mat-palette($mat-pink, 700, 300, 900);
// $theme: mat-light-theme($primary, $accent, $warn); // Or mat-dark-theme

 //$primary-color:#F44336;

// gruen
// $primary: mat-palette($mat-green, 600, 300, 900);
// $accent: mat-palette($mat-blue-grey, 700, 300, 900);
// $warn: mat-palette($mat-pink, 700, 300, 900);
// $theme: mat-light-theme($primary, $accent, $warn);


// Or mat-dark-theme

// $primary: mat-palette($mat-green, 600, 300, 900);
// $accent: mat-palette($mat-blue-grey, 700, 300, 900);
// $warn: mat-palette($mat-pink, 700, 300, 900);
// $theme: mat-light-theme($primary, $accent, $warn); // Or mat-dark-theme

// @include angular-material-theme($theme);

// $primary-color:#4CAF50;



//$primary: mat-palette($mat-light-blue, 600, 300, 900);
// $primary: mat-palette($mat-orange, 900, 900, 900);
// $accent: mat-palette($mat-blue-grey, 700, 300, 900);
// $warn: mat-palette($mat-pink, 700, 300, 900);
// $theme: mat-light-theme($primary, $accent, $warn); 
// @include angular-material-theme($theme);

// bei mat-light-green wechselt die schriftfarbe

// // themes for notifications
// //$my-theme-info: mat-palette($mat-blue);
// //$my-theme-success: mat-palette($mat-green);
// //$my-theme-warning: mat-palette($mat-yellow);
// //$my-theme-error: mat-palette($mat-red);

// //$my-app-theme: mat-light-theme($my-theme-primary, $my-theme-accent,$my-theme);
// @include angular-material-theme($my-theme);


// .alternate-theme {
//     $alternate-primary: mat-palette($mat-light-blue);
//     $alternate-accent:  mat-palette($mat-yellow, 400);
//     $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
//     @include angular-material-theme($alternate-theme);
// }



// koennte auch noch verwendet werden
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
//$primary-color:#673AB7;

//@import '@angular/material/prebuilt-themes/purple-green.css';
//@import '@angular/material/prebuilt-themes/pink-bluegrey.css';


$header-picture-height : "100px";

// recommended value "100%"
$application-width: "100%";

$font-family: 'Roboto', sans-serif;


$primary-color-font: #ffffff;
$list-background-color:#E8EAF6;

//$list-background-color:#E0E0E0;
$scrollbar-hover-color: #BBDEFB;

$line-color:white;
$background-color:white;

$change-desktop-footer-width:600px;
$footer-height:2.3em;
$destop-footer-height:2.5em;

$stepper-change-midformat-size:1024px; //align the inner content to the center
$increasing-screen-size: 1300px;
$location-row-high:10em;

$location-row-high-small:18em;

/* if it is required to define a small hight */
// $location-item-high:5em;
// $location-item1-high-small:2em;
// $location-item2-high-small:4em;
// $location-item3-high-small:2em;

$location-item1-width:28%;
$location-item2-width:25%;
$location-item3-width:28%;

$location-item1-width-big:15em;
$location-item2-width-big:18em;
$location-item3-width-big:10em;

$dayNoEntryFontColor:grey;
$dayNoEntryBackGroundColor:white;